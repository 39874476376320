<template>
    <h4>"I Love You" NFT</h4>
    <h6>
        Spreading love, positivity, and celebrating all the different ways that love can bring us together!
    </h6>
    <h4>Description</h4>
    <h6>
        <li>Is a digital asset designed to spread love and positivity.</li>
        <li>Features a simple yet powerful message that reads "I Love You" along with the heart symbol.</li>
        <li>Represents the power of love and human connection.</li>
        <li>Is a one-off - there is only one original “I Love You!” NFT that travels the world.</li>
    </h6>
    <h4>Concept</h4>
    <h6>
        Our goal is to create an unbroken chain of love and kindness that is passed from person to person, encouraging them to share the love by sending a message of kindness or appreciation to someone they care about. As the NFT is passed from one person to another, it accumulates a history of all the positive messages and acts of kindness that have been associated with it, making it a symbol of hope and positivity in a world that can often feel divided and disconnected.
    </h6>
    <h4>Social Experiment</h4>
    <h6>
        The "I Love You" project is a social experiment that aims to bring people together through the power of love. By participating in this project, you are joining a community of people who believe in the power of love to make a difference in the world.
    </h6>
    <h4>Contribution</h4>
    <h6>When someone buys and sends the "I Love You" NFT, they have the option to add a little extra money, which goes towards supporting the developing team. This helps to ensure that the project can continue to spread love and positivity to even more people around the world.</h6>
    <h4>Symbol</h4>
    <h6>The "I Love You" NFT is not just a digital asset but a symbol of hope and positivity. By participating in this project, you are spreading love and positivity and celebrating all the different ways that love can bring us together.</h6>
    <h4>Message</h4>
    <h6>Let's spread love and positivity together with the "I Love You" NFT and create an unbroken chain of love and kindness that will make a difference in the world. Remember, the "I Love You" NFT must be passed on within 72 hours after receiving; otherwise, it becomes claimable by others and sent on.</h6>
</template>