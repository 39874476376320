<template>
    <h1>{{ title }}</h1>
</template>

<script setup>

import { ref } from 'vue'
const title = ref('List of all the posts')

</script>
