<template>
    <nav>
        <div class="nav-wrapper">
            <div class="container">
                <router-link to="/" class="brand-logo">I Love You NFT</router-link>
                <ul id="nav-mobile" class="right hide-on-med-and-down">
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/posts">Posts</router-link></li>
                    <li><router-link to="/team">Team</router-link></li>
                    <li><router-link to="/about">About</router-link></li>
                </ul>
            </div>
        </div>
    </nav>
</template>