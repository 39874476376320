import { createRouter, createWebHashHistory } from 'vue-router'

import About from './views/About'
import Home from './views/Home'
import Team from './views/Team'
import Posts from './views/Posts'

const routes = [
    { path: '/', component: Home },
    { path: '/posts', component: Posts },
    { path: '/about', component: About },
    { path: '/team', component: Team },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

export default router